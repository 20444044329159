import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import Fade from "react-reveal/Fade";
import {MdAdd, MdArrowBack, MdMoreHoriz, MdSync, MdWidgets} from "react-icons/md";
import {useMediaQuery} from "react-responsive";
import axios from "axios";
import {getChildrenTags, getOwnedTags, getTag} from "../../util/enpoints";
import TagElement from "./crud/components/TagElement";
import TagPanel from "./crud/components/TagPanel";
import CrudTimetableElement from "./crud/components/CrudTimetableElement";
import {Helmet} from "react-helmet";
import NavBar from "../navBar";
import ConfirmModal from "../modal/confirm";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {useLocalization} from "gatsby-theme-i18n";

export default function Crud() {
    const {locale} = useLocalization()
    const {t} = useTranslation(["translation", "crud"])

    const isTablet = useMediaQuery({maxWidth: 1000});

    const [previousTags, setPreviousTags] = useState([])
    const [loading, setLoading] = useState(true)
    const [currentTag, setCurrentTag] = useState(null)
    const [data, setData] = useState(null)
    const [showPanel, setShowPanel] = useState(true)
    const [tagPanel, setTagPanel] = useState("hidden")

    const [tagPanelName, setTagPanelName] = useState("")
    const [tagPanelSH, setTagPanelSH] = useState("")
    const [tagPanelId, setTagPanelId] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [onModalCancel, setOnModalCancel] = useState(null)
    const [onModalSuccess, setOnModalSuccess] = useState(null)
    const [modalContent, setModalContent] = useState("")
    const [modalSuccessColor, setModalSuccessColor] = useState("bg-red text-white")

    const openDeleteModal = useCallback((content, callback) => {
        setOnModalCancel(() => () => setIsModalOpen(false))
        setOnModalSuccess(() => () => {
            setIsModalOpen(false)
            callback()
        })
        setModalSuccessColor("bg-red text-white")
        setModalContent(content)
        setIsModalOpen(true)
    }, [])


    useEffect(() => {
        setShowPanel(!isTablet);
    }, [isTablet])

    async function goBack() {
        let tag = previousTags[previousTags.length - 1]
        setPreviousTags((prev) => {
            const arr = [...prev]
            arr.pop()
            return arr
        })
        setLoading(true);
        let cancelToken = axios.CancelToken.source();
        let response;
        if (tag != null) {
            response = await getChildrenTags(tag.uuid, locale, cancelToken.token)
        } else {
            response = await getOwnedTags(locale, cancelToken.token)
        }
        setData(response.data);
        setCurrentTag(tag);
        setLoading(false);
    }

    async function refresh() {
        let tag = currentTag;
        setLoading(true);
        let cancelToken = axios.CancelToken.source();
        let response;
        if (tag != null) {
            response = await getChildrenTags(tag.uuid, locale, cancelToken.token)
        } else {
            response = await getOwnedTags(locale, cancelToken.token)
        }
        setData(response.data);
        setLoading(false);
    }

    async function refreshCurrentTag() {
        let tag = currentTag;
        setLoading(true);
        let cancelToken = axios.CancelToken.source();
        let response;
        if (tag != null) {
            response = await getTag(tag.uuid, locale, cancelToken.token)
        } else {
            response = await getOwnedTags(locale, cancelToken.token)
        }
        setCurrentTag(response.data);
        setLoading(false);
    }

    async function getNext(tag) {
        setLoading(true);
        let cancelToken = axios.CancelToken.source();
        let response = await getChildrenTags(tag.uuid, 'en', cancelToken.token)
        setData(response.data);
        setPreviousTags((prev) => {
            return [...prev, currentTag]
        })
        setCurrentTag(tag);
        setLoading(false);
    }

    useEffect(() => {
        let cancelToken;
        let cleanup = false;

        async function getData() {
            try {
                setLoading(true);
                cancelToken = axios.CancelToken.source();
                let response = await getOwnedTags(locale, cancelToken.token)
                if (cleanup) {
                    return;
                }
                setData(response.data);
                setLoading(false)
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    for (let errMsg of e.response.data.errors) {
                        toast.error(errMsg);
                    }
                } else {
                    throw e;
                }
            }

        }

        getData();
        return () => {
            cleanup = true;
            cancelToken.cancel('Only one request can be performed');
        };
    }, [locale])
    return (<div className="flex flex-col h-full">
        <Helmet title={currentTag != null ? `${t("manage")} ${currentTag?.name}` : t("managementPanel")} defer={false}/>
        <NavBar title={t("manageTimetables")} navButton={{icon: MdWidgets, location: "/owner"}}/>
        <ConfirmModal isOpen={isModalOpen} onCancel={onModalCancel} onSuccess={onModalSuccess}
                      content={modalContent} color={modalSuccessColor}/>
        <div className="flex justify-start h-12 w-full border-b-2 border-white bg-transparent"
        >
            <div className="flex justify-center items-center h-full">
                {isTablet ?
                    <button aria-label="Open Side Panel"
                            className="text-white text-2xl ml-2 px-2 text-center border-r-2 border-white transition duration-500 ease-in-out hover:text-orange transform hover:scale-110"
                            onClick={() => setShowPanel(!showPanel)}><MdMoreHoriz/></button> : ""
                }
            </div>
        </div>
        <div className="flex flex-row h-full relative">
            <div className={`${isTablet ? " w-0" : " w-1/4"}`}/>
            <Fade collapse left when={showPanel}>

                <div
                    className={`overflow-y-auto overflow-x-hidden absolute left-0 top-0 ${isTablet ? " w-2/3" : " w-1/4"} bg-blue-100 h-full  border-2 border-t-0 border-white `}>
                    <div className="flex h-full flex-col items-center ">

                        {loading ? null : tagPanel === "hidden" && currentTag != null ?
                            <button
                                className="transition duration-500 ease-in-out hover:text-orange text-white  cursor-pointer w-full flex items-center pb-2 mt-2 justify-center space-x-2 border-b-2 border-white"
                                onClick={() => {
                                    setTagPanelName("")
                                    setTagPanelSH("")
                                    setTagPanel("new")
                                }}>
                                <p className={`text-2xl text-center pb-1`}><MdAdd/></p>
                                <p className={`text-lg text-center`}>{t("crud:newFolder")}</p>
                            </button> : null
                        }
                        {tagPanel !== "hidden" ?
                            <TagPanel tagPanelName={tagPanelName} setTagPanelName={setTagPanelName}
                                      tagPanelSH={tagPanelSH} setTagPanelSH={setTagPanelSH} tagPanel={tagPanel}
                                      setTagPanel={setTagPanel}
                                      tagId={tagPanel === "edit" ? tagPanelId : currentTag.uuid}
                                      refresh={refresh}/> :
                            (
                                loading ?
                                    (<div className="flex justify-center items-center h-screen">
                                        <h1 className="animate-spin  text-white text-5xl"><MdSync/></h1>
                                    </div>) : (data.length === 0 ? "" :
                                            <TagElement isTablet={isTablet} data={data} getNext={getNext}
                                                        setTagPanel={setTagPanel}
                                                        setTagPanelName={setTagPanelName} setTagPanelSH={setTagPanelSH}
                                                        setTagPanelId={setTagPanelId}
                                                        refresh={refresh} openDeleteModal={openDeleteModal}/>
                                    )
                            )
                        }

                        <Fade right when={previousTags.length > 0 && tagPanel === "hidden"}>
                            <button className="border-2 border-blue-100 group fixed left-2 bottom-2 flex justify-center items-center text-white text-2xl cursor-pointer h-10 px-2 py-2 rounded-full
                        transition duration-500 ease-in-out transform hover:scale-110 space-x-2 bg-orange px-4 h-14 w-14"
                                    onClick={goBack}>
                                <MdArrowBack
                                    className={`transition duration-500 ease-in-out transform group-hover:scale-125`}/>
                            </button>
                        </Fade>

                    </div>
                </div>
            </Fade>
            {currentTag == null ? (<div className="flex justify-center items-center flex-grow h-full">
                    <h1 className="text-white text-center text-4xl md:text-5xl lg:text-7xl">{t("crud:noFolderSelected")}</h1>
                </div>)
                : (<CrudTimetableElement currentTag={currentTag} refreshTag={refreshCurrentTag}
                                         className={`w-auto h-full mx-auto ${showPanel && isTablet ? "hidden" : ""}`}/>)}
        </div>
    </div>)
}
