import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import firebase from "gatsby-plugin-firebase";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useLocalization } from "gatsby-theme-i18n";
import { EmptyTimetable, Tag, TClassSimple, TimetableSimple } from "../../../../util/enpoints/types";
import {
  compareTimetable,
  deleteTimetable,
  getRawTimetable,
  getTimetablePreview,
  uploadTimetable,
} from "../../../../util/enpoints";
import { errorMessage } from "../../../../util/enpoints/error";
import useTimetable from "../../../../hooks/useTimetable";
import EditTimetable from "../../../elements/editTimetable";
import ConfirmModal from "../../../modal/confirm";

type Props = {
  currentTag: Tag;
  refreshTag: CallableFunction;
  className: string;
};

const CrudTimetableElement: React.FC<Props> = (props) => {
  const { locale } = useLocalization();
  const { t } = useTranslation(["translation", "crud"]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [onModalCancel, setOnModalCancel] = useState(null);
  const [onModalSuccess, setOnModalSuccess] = useState(null);
  const [modalContent, setModalContent] = useState("");
  const [modalSuccessColor, setModalSuccessColor] = useState("bg-red text-white");
  const [published, setPublished] = useState(true);
  const [timeTable, dispatchTimeTable, handleUndo, handleRedo, canUndo, canRedo] = useTimetable();

  const openDeleteModal = useCallback((content: string, callback: () => void) => {
    setOnModalCancel(() => () => setIsModalOpen(false));
    setOnModalSuccess(() => () => {
      setIsModalOpen(false);
      callback();
    });
    setModalSuccessColor("bg-red text-white");
    setModalContent(content);
    setIsModalOpen(true);
  }, []);

  const seeIfPublished = useCallback(
    (tt?: TimetableSimple<TClassSimple>) => {
      async function calculatePublished(): Promise<void> {
        try {
          const canonicalVersion = (
            await getTimetablePreview(props.currentTag.uuid, tt == null ? timeTable : tt, locale)
          ).data;
          const comparison = (await compareTimetable(canonicalVersion, props.currentTag.uuid, locale)).data;
          setPublished(comparison.isSame);
        } catch (e) {
          if (axios.isAxiosError(e)) {
            for (const errMsg of e.response.data.errors) {
              toast.error(errMsg);
            }
          } else {
            throw e;
          }
        }
      }

      toast.promise(calculatePublished(), {
        loading: t("crud:checkPublishedInProgress"),
        success: t("crud:checkPublishedSuccess"),
        error: t("crud:checkPublishedError"),
      });
    },
    [t, props.currentTag.uuid, timeTable, locale]
  );

  const saveDraft = useCallback(() => {
    async function setData(): Promise<void> {
      const user = firebase.auth().currentUser;
      const doc = firebase.firestore().doc(`user-data/${user.uid}/drafts/${props.currentTag.uuid}`);
      console.log("saving", timeTable);
      await doc.set({ timetable: timeTable }, { merge: true });
    }

    const dataPromise = setData();
    dataPromise.then(() => seeIfPublished());

    toast.promise(dataPromise, {
      loading: t("crud:saveDraftInProgress"),
      success: t("crud:saveDraftSuccess"),
      error: t("crud:saveDraftError"),
    });
  }, [t, props.currentTag.uuid, timeTable, seeIfPublished]);

  const loadDraft = useCallback(() => {
    async function getData(): Promise<void> {
      const user = firebase.auth().currentUser;
      const data = (
        await firebase.firestore().doc(`user-data/${user.uid}/drafts/${props.currentTag.uuid}`).get()
      ).data();
      if (data == null || data?.timetable == null) {
        dispatchTimeTable({ type: "reset" });
        seeIfPublished(EmptyTimetable);
      } else {
        dispatchTimeTable({ type: "set", timeTable: data.timetable });
        seeIfPublished(data.timetable);
      }
    }

    const dataPromise = getData();
    toast.promise(dataPromise, {
      loading: t("crud:loadDraftInProgress"),
      success: t("crud:loadDraftSuccess"),
      error: t("crud:loadDraftError"),
    });
  }, [dispatchTimeTable, props.currentTag.uuid, seeIfPublished, t]);

  const publishDraft = useCallback(() => {
    async function publishTimetable(): Promise<void> {
      try {
        const canonicalVersion = (await getTimetablePreview(props.currentTag.uuid, timeTable, locale)).data;
        await uploadTimetable(props.currentTag.uuid, canonicalVersion, locale);
        setPublished(true);
        await props.refreshTag();
      } catch (e) {
        if (axios.isAxiosError(e)) {
          for (const errMsg of e.response.data.errors) {
            toast.error(errMsg);
          }
        } else {
          throw e;
        }
      }
    }

    toast.promise(publishTimetable(), {
      loading: t("crud:publishTimetableInProgress"),
      success: t("crud:publishTimetableSuccess"),
      error: t("crud:publishTimetableError"),
    });
  }, [t, props, timeTable, locale]);

  const confirmDeleteTimetable = useCallback(() => {
    async function deleteTT(): Promise<void> {
      await deleteTimetable(props.currentTag.uuid, locale);
      setPublished(false);
      await props.refreshTag();
    }

    openDeleteModal(t("crud:deleteTimetablePrompt"), () => {
      toast.promise(deleteTT(), {
        loading: t("crud:deleteTimetableInProgress"),
        success: t("crud:deleteTimetableSuccess"),
        error: (err) => errorMessage(err),
      });
    });
  }, [locale, openDeleteModal, props, t]);

  const confirmResetTimetable = useCallback(() => {
    async function deleteTT(): Promise<void> {
      const timetable = (await getRawTimetable(props.currentTag.uuid, locale)).data;
      dispatchTimeTable({ type: "set", timeTable: timetable });
      seeIfPublished(timetable);
    }

    openDeleteModal(t("crud:replacingTimetablePrompt"), () => {
      toast.promise(deleteTT(), {
        loading: t("crud:replacingTimetableInProgress"),
        success: t("crud:replacingTimetableSuccess"),
        error: (err) => errorMessage(err),
      });
    });
  }, [dispatchTimeTable, locale, openDeleteModal, props.currentTag.uuid, seeIfPublished, t]);

  useEffect(() => {
    if (props.currentTag != null) {
      loadDraft();
    }
  }, [props.currentTag]);

  return (
    <div className={`flex flex-col w-3/4 ${props.className}`}>
      <ConfirmModal
        isOpen={isModalOpen}
        onCancel={onModalCancel}
        onSuccess={onModalSuccess}
        content={modalContent}
        color={modalSuccessColor}
      />
      <EditTimetable
        timeTable={timeTable}
        dispatchTimeTable={dispatchTimeTable}
        handleUndo={handleUndo}
        handleRedo={handleRedo}
        canUndo={canUndo}
        canRedo={canRedo}
        title={props.currentTag?.name}
      />
      <div className="flex flex-row h-28 w-full justify-center space-x-4 items-center">
        <button
          type="button"
          className="transition duration-500 ease-in-out text-white cursor-pointer text-xl border-b-2 hover:border-green"
          onClick={saveDraft}
        >
          {t("crud:saveDraft")}
        </button>
        <button
          type="button"
          className="transition duration-500 ease-in-out text-white cursor-pointer text-xl border-b-2 hover:border-yellow"
          onClick={loadDraft}
        >
          {t("crud:loadLastSave")}
        </button>
        {props.currentTag?.hasTimetable ? (
          <>
            <button
              type="button"
              className="transition duration-500 ease-in-out text-white cursor-pointer text-xl border-b-2 hover:border-red"
              onClick={confirmResetTimetable}
            >
              {t("crud:loadPublicTimetable")}
            </button>
            <button
              type="button"
              className="transition duration-500 ease-in-out text-white cursor-pointer text-xl border-b-2 hover:border-red"
              onClick={confirmDeleteTimetable}
            >
              {t("crud:deletePublicTimetable")}
            </button>
          </>
        ) : null}
        {published ? (
          <button
            type="button"
            className="transition duration-500 ease-in-out text-gray-300 cursor-not-allowed text-xl border-b-2 border-gray-300"
          >
            {t("crud:alreadyPublished")}
          </button>
        ) : (
          <button
            type="button"
            className="transition duration-500 ease-in-out text-white cursor-pointer text-xl border-b-2 hover:border-green"
            onClick={publishDraft}
          >
            {t("crud:publishCurrentDraft")}
          </button>
        )}
      </div>
    </div>
  );
};

export default CrudTimetableElement;
