import * as React from "react";
import {LocalizedLink as Link} from "gatsby-theme-i18n";
import {MdAccountCircle, MdEdit} from "react-icons/md";
import Zoom from "react-reveal/Zoom";
import {Helmet} from "react-helmet";
import NavBar from "../navBar";
import {useTranslation} from "react-i18next";

export default function Main() {
    const {t} = useTranslation()
    return (<div className="flex flex-col h-full">
        <Helmet title={t("controlPanel")} defer={false}/>
        <NavBar title={t("controlPanel")} navButton={{icon: MdAccountCircle, location: "/app/account"}}/>
        <div className="flex h-full justify-center flex-col items-center">
            <Zoom cascade>
                <div className="flex flex-col h-full justify-center space-y-6">
                    <div>
                        <Link to="/owner/crud" className="flex justify-start items-center text-blue-100 text-xl cursor-pointer h-10 px-2 py-2 rounded-2xl
                        transition duration-500 ease-in-out transform hover:scale-110 space-x-2 bg-white shadow-xl px-4">
                            <MdEdit/>
                            <span>{t("manageTimetables")}</span>
                        </Link>
                    </div>
                </div>
            </Zoom>
        </div>
    </div>)
}
