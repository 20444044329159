import * as React from "react";
import { LocalizedRouter as Router } from "gatsby-theme-i18n";
import firebase from "gatsby-plugin-firebase";
import { useTranslation } from "react-i18next";
import Main from "../../components/owner-only/main";
import { useUserInfo } from "../../hooks/userInfo";
import Crud from "../../components/owner-only/crud";
import useStaticAuthState from "../../hooks/staticAuthState";
import Seo from "../../components/seo";
import NotFoundPage from "../404";
import { useLocalizedHelpers } from "../../hooks/useLocalizedHelpers";
import StandardToaster from "../../components/standardToaster";

export default function App({ location }) {
  const [user] = useStaticAuthState(firebase.auth);
  const userInfo = useUserInfo(user);
  const { t } = useTranslation();
  const { navigate } = useLocalizedHelpers();
  if (user == null) {
    // If we’re not logged in, redirect to the home page.
    if (typeof window !== "undefined") navigate(`/login?redirect=${location.pathname}`, { replace: true });
    return (
      <div className="flex justify-center items-center flex-grow h-screen">
        <h1 className="text-white text-center text-4xl md:text-5xl lg:text-7xl">{t("notAuthenticated")}</h1>
      </div>
    );
  }
  if (userInfo == null) {
    return (
      <div className="flex justify-center items-center flex-grow h-screen">
        <h1 className="text-white text-center text-4xl md:text-5xl lg:text-7xl">{t("loading")}</h1>
      </div>
    );
  }
  if (!userInfo.isOwner) {
    return (
      <div className="flex justify-center items-center flex-grow h-screen">
        <h1 className="text-white text-center text-4xl md:text-5xl lg:text-7xl">{t("notAuthorized")}</h1>
      </div>
    );
  }
  return (
    <>
      <Seo title={t("controlPanel")} description={t("controlPanelDescription")} />
      <StandardToaster
        toastOptions={{
          className: "border-white border-2 shadow-lg",
          style: {
            color: "white",
            backgroundColor: "#101628",
          },
          error: {
            className: "border-transparent border-2 shadow-lg",
            style: {
              backgroundColor: "#ff8080",
            },
          },
        }}
        position="bottom-center"
      />
      <Router className="h-full" basePath="/owner">
        <NotFoundPage default />
        <Main path="/" />
        <Crud path="/crud" />
      </Router>
    </>
  );
}
