import {deleteTag} from "../../../../util/enpoints";
import * as React from "react";
import axios from "axios";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {useLocalization} from "gatsby-theme-i18n";

export default function TagElement(props) {
    const {locale} = useLocalization()
    const {t} = useTranslation(["translation", "crud"])
    return (<div
        className="flex flex-col flex-shrink justify-start px-2 lg:items-center w-full h-full ">
        {

            props.data.map((elem, index) => (
                <div
                    className={` group flex flex-col cursor-pointer ${props.isTablet ? "" : "w-full"} flex-row bg-transparent space-y-2 m-2 py-2`}
                    key={index.toString()}
                >
                    <div
                        className="transition duration-500 ease-in-out flex flex-row justify-evenly opacity-50  group-hover:opacity-90">

                        <div className="border-r-2 border-white "/>
                        <p className="text-white w-full text-center transition duration-250 ease-in-out border-t-2 border-white"
                        >|</p>
                        <div className="border-r-2 border-white  "/>
                    </div>
                    <p className="transition duration-500 transform group-hover:scale-110 text-white text-center ">{elem.name}</p>
                    <div
                        className="transition duration-500 ease-in-out flex flex-row justify-evenly opacity-50  group-hover:opacity-90">
                        <div className="border-r-2 border-white  "/>
                        <button
                            className="text-white w-full text-center transition duration-250 ease-in-out hover:border-green border-b-2 border-white"
                            onClick={() => {
                                props.setTagPanelId(elem.uuid);
                                props.setTagPanelName(elem.name);
                                props.setTagPanelSH(elem.shorthand)
                                props.setTagPanel("edit")
                            }}>{t("edit")}
                        </button>
                        <div className="border-r-2 border-white"/>
                        <button
                            className="text-white w-full text-center transition duration-250 ease-in-out hover:border-orange border-b-2 border-white"
                            onClick={() => props.getNext(elem)}>{t("access")}
                        </button>
                        <div className="border-r-2 border-white"/>
                        <button
                            className="text-white w-full text-center transition duration-250 ease-in-out hover:border-red border-b-2 border-white"
                            onClick={() => props.openDeleteModal(t("crud:deleteFolderWarning", {name: elem.name}), () => {
                                deleteTag(elem.uuid, locale).then(() => {
                                    props.refresh();
                                }).catch((e) => {
                                    if (axios.isAxiosError(e)) {
                                        for (let errMsg of e.response.data.errors) {
                                            toast.error(errMsg);
                                        }
                                    } else {
                                        console.error(e);
                                        throw e;
                                    }
                                })
                            })}>{t("delete")}
                        </button>
                        <div className="border-r-2 border-white"/>
                    </div>
                </div>
            ))
        }
    </div>)
}
