import {createTag, editTag} from "../../../../util/enpoints";
import * as React from "react";
import {Dispatch, SetStateAction, useCallback} from "react";
import {TagId} from "../../../../util/enpoints/types";
import {toast} from "react-hot-toast";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {useLocalization} from "gatsby-theme-i18n";

type TagPanelEnum = "hidden" | "new" | "edit"
type Props = {
    tagPanel: TagPanelEnum;
    setTagPanel: Dispatch<SetStateAction<TagPanelEnum>>;
    tagPanelName: string;
    setTagPanelName: Dispatch<SetStateAction<string>>;
    tagPanelSH: string;
    setTagPanelSH: Dispatch<SetStateAction<string>>;
    tagId: TagId;
    refresh: Function;
}

const TagPanel: React.FC<Props> = (props) => {
    const {locale} = useLocalization()
    const {t} = useTranslation(["translation", "crud"])
    const submitTag = useCallback(async () => {
        if (props.tagPanelName == "" && props.tagPanelSH == "") {
            toast.error(t("crud:editFieldError"))
            return;
        }
        if (props.tagPanel === "new") {
            try {
                await createTag(props.tagPanelName, props.tagPanelSH, props.tagId, locale)
                props.refresh()
                props.setTagPanel("hidden")
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    for (let errMsg of e.response.data.errors) {
                        toast.error(errMsg);
                    }
                } else {
                    throw e;
                }
            }

        } else if (props.tagPanel === "edit") {
            try {
                await editTag(props.tagPanelName, props.tagPanelSH, props.tagId, locale);
                props.refresh()
                props.setTagPanel("hidden")
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    for (let errMsg of e.response.data.errors) {
                        toast.error(errMsg);
                    }
                } else {
                    throw e;
                }
            }
        }
    }, [props]);

    return (<div
        className=" flex px-4 flex-col justify-start mt-2 items-center h-full w-full space-y-2">
        <div className=" h-10 flex bg-transparent w-full">
            <div
                className="flex flex-col justify-center  border-b-2 border-white  items-center">
                <p className="text-white pl-1 align-middle text-sm border-r-2 border-white px-2">
                    {t("crud:name")}
                </p>
            </div>
            <input type="text" placeholder={t("crud:name")}
                   value={props.tagPanelName}
                   onChange={e => props.setTagPanelName(e.target.value)}
                   className={`transition duration-500 border-b-2 border-white focus:border-orange qstext pl-2 w-full bg-transparent text-white focus:outline-none`}/>
        </div>
        <div className="h-10 flex bg-transparent rounded-lg w-full">
            <div
                className="flex flex-col justify-center  border-b-2 border-white   items-center">
                <p className="text-white pl-1 align-middle text-sm border-r-2 border-white px-2">
                    {t("crud:shorthand")}
                </p>
            </div>
            <input type="text" placeholder={t("crud:shorthand")}
                   value={props.tagPanelSH}
                   onChange={e => props.setTagPanelSH(e.target.value)}
                   readOnly={props.tagPanel === "edit"}
                   className={`qstext pl-2 w-full transition duration-500 border-b-2 border-white focus:border-orange bg-transparent text-white focus:outline-none`}/>
        </div>
        <div className="h-10 flex bg-transparent space-x-2 rounded-lg w-full">
            <div onClick={() => {
                props.setTagPanel("hidden")
            }}
                 className="cursor-pointer flex flex-col w-full justify-center
                                        border-b-2 border-white transition duration-500 hover:border-red   items-center">
                <p className="text-white pl-1 align-middle text-lg px-2">
                    {t("cancel")}
                </p>
            </div>
            <div onClick={() => toast.promise(submitTag(), {
                loading: t("crud:crudInProgress"),
                success: t("crud:crudSuccess"),
                error: t("crud:crudError")
            })}
                 className=" cursor-pointer flex flex-col w-full justify-center transition
                                        duration-500 hover:border-green  border-b-2 border-white   items-center">
                <p className="text-white pl-1 align-middle text-lg px-2">
                    {props.tagPanel === "new" ? t("add") : t("save")}
                </p>
            </div>
        </div>
    </div>)
}
export default TagPanel;
